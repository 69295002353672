import React from "react";
import SEO from "../../components/CommonComponents/SEO";
import Layout from "../../components/CommonComponents/Layout";
import Solutions from "../../components/SingleService/Common/Solutions";
import Keys from "../../components/SingleService/Common/Keys";
import Experience from "../../components/SingleService/Common/Experience";
import Technologies from "../../components/SingleService/Common/Technologies";
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise";
import dataForWeb from "../../information/Services/dataForWeb";
import { mainUrl } from "../../js/config";
import Hero from "../../components/SingleService/Common/Hero";
import WebAppDevelopment from "../../components/SingleService/Web/WebAppDevelopment";
import Choose from "../../components/SingleService/Common/Choose";
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json"
import Cases from "../../components/CommonComponents/Cases"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function Web() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";
  const {
    hero,
    webAppDevelopment,
    solutions,
    choose,
    keys,
    technologies,
    ogImage,
  } = dataForWeb;

  return (
    <section className="web">
      <SEO
        title="Custom Web Application Development Services | OS-System"
        description="OS-System is #1 in terms of web app development services. We create apps for education, e-commerce, logistics, finance, and other industries. If you need custom web apps, PWAs, or API integration, feel free to contact us."
        canonical={`${mainUrl}/services/web/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={100}>
        <Hero data={hero} />
        <WebAppDevelopment data={webAppDevelopment} />
        <Experience />
        <Solutions data={solutions} />
        <Choose data={choose} />
        <Keys data={keys} />
        <Technologies data={technologies} />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
          customClass="cloud"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </section>
  )
}
